/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-03-12 18:14:13
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-03 16:46:49
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerRebate = params => axios({
    url: '/api/dealer/dealer/dealerInfo/areaRebateList',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})